import { Message } from "../models/message.interface";

export class LoadMessages {
  public static readonly type = "[Messages] load messages";
  constructor(
    public skipCount: number,
    public maxResultsCount: number
  ) {}
}

export class AddMessage {
  public static readonly type = "[Messages] add message";
  constructor(
    public message: Message,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class UpdateMessage {
  public static readonly type = "[Messages] update message";
  constructor(
    public message: Message,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class RemoveMessage {
  public static readonly type = "[Messages] remove message";
  constructor(
    public messageId: string,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class SendGroupMessage {
  public static readonly type = "[Messages] send group message";
  constructor(
    public messageId: string,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}