import { AsyncPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DatePickerComponent } from 'src/app/shared/components/date-picker/date-picker.component';
import { DialogFooterComponent } from 'src/app/shared/components/dialog-footer/dialog-footer.component';
import { Message } from '../../models/message.interface';
import { AddMessage, UpdateMessage } from '../../state/messages.actions';

@Component({
  selector: 'app-message-form',
  standalone: true,
  imports: [ReactiveFormsModule, AsyncPipe, DatePickerComponent, DialogFooterComponent],
  templateUrl: './message-form.component.html'
})
export class MessageFormComponent implements OnInit {

  title: string;
  messageForm: UntypedFormGroup;

  @Select(state => state.messages.saving) isSaving$: Observable<boolean>;

  constructor(
    private dialogRef: MatDialogRef<MessageFormComponent>,
    private dialog: MatDialog,
    private store: Store,
    private _notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: { message: Message }
  ) {
    this.title = '';
    this.dialogRef.disableClose = true;
    this.subscribeBackdropDialog();
  }

  private subscribeBackdropDialog(): void {
    this.dialogRef.backdropClick().subscribe(result => {
      this.dialog.open(ConfirmDialogComponent, {
        width: "400px",
        data: {
          message: `Are you sure you want to leave?`,
          onConfirm: () => {
            this.dialog.closeAll();
            this.dialogRef.close();
          }
        }
      });
    });
  }

  ngOnInit(): void {
    this.setModalTitle();
    this.createForm();
  }

  private setModalTitle(): void {
    this.title = this.data?.message ? 'Edit Message' : 'Create New Message';
  }

  private createForm(): void {
    this.messageForm = new UntypedFormGroup({
      "message": new UntypedFormControl(this.data.message?.message || '', [Validators.required, Validators.maxLength(500)]),
      "expirationDate": new UntypedFormControl(this.data.message?.expirationDate || ''),
      "activationDate": new UntypedFormControl(this.data.message?.activationDate || '')
    })
  }

  save(): void {
    this.dialogRef.disableClose = true;
    this.data.message ? this.dispatchUpdateMessage() : this.dispatchAddMessage();
  }

  private dispatchUpdateMessage(): void {
    const message: Message = this.messageForm.value;
    message.id = this.data.message.id;
    this.store.dispatch(new UpdateMessage(
      message,
      () => {
        this._notificationService.showSuccess("The message was successfully updated");
        this.dialogRef.close();
      },
      () => {
        this._notificationService.showError("There was a problem updating the message. Please try again.");
      },
    ));
  }

  private dispatchAddMessage(): void {
    this.store.dispatch(new AddMessage(
      this.messageForm.value,
      () => {
        this._notificationService.showSuccess("The message was successfully created");
        this.dialogRef.close();
      },
      () => {
        this._notificationService.showError("There was a problem creating the message. Please try again.");
      },
    ));
  }
  
  closeDialog(): void {
    this.dialogRef.close();
  }

}
