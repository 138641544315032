<h3 class="font-extrabold mb-6">{{ title }}</h3>

<form (ngSubmit)="save()" [formGroup]="messageForm" class="overflow-x-hidden">
	<fieldset [disabled]="isSaving$ | async">
		<div class="grid grid-cols-6">
			<div class="mr-4 mb-4 col-span-3">
				<app-date-picker [placeholder]="'Select Activation Date'"
												 [label]="'Activation Date'"
												 [formControl]="messageForm.get('activationDate')"
												 class="block" /> 
			</div>
			<div class="col-span-3">
				<app-date-picker [placeholder]="'Select Expiration Date'"
												 [label]="'Expiration Date'"
												 [formControl]="messageForm.get('expirationDate')"
												 class="block" />
			</div>
		</div>
		<div class="grid grid-cols-6">
			<label class="col-span-6 base-input-label w-full mt-2 mr-1">
        Message*
        <textarea formControlName="message"
									cols="40"
									[placeholder]="'Message'"
									maxLength="500"
									class="border-2 rounded mt-1 text-gray-600 p-4">
				</textarea>
      </label>
		</div>
		<app-dialog-footer [isSaving]="isSaving$ | async"
											 [submitActionName]="data.message ? 'Save' : 'Add'"
											 [valid]="messageForm.valid"
											 (cancelAction)="closeDialog()" />
	</fieldset>
</form>
